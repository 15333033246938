<!--
 * @Description: 我的订单页面组件
 * @Author: hai-27
 * @Date: 2020-02-20 17:21:54
 * @LastEditors: hai-27
 * @LastEditTime: 2020-02-27 13:36:27
 -->
<template>
	<div class="uc-box uc-main-box">
		<div class="uc-content-box order-list-box">
			<!-- 我的订单头部 -->
			<div class="box-hd">
				<h1 class="title">支付设置</h1>
				<div class="clearfix"></div>
			</div>
			<!-- 我的订单头部END -->

			<!-- 我的订单主要内容 -->
			<div class="box-bd">
				<div class="userInfo">
					<!-- 	<div class="box">
						<div class="text">
							<span class="redxx">*</span>
							实名认证：
						</div>
						<div class="rt">
							{{udata.isbaofu?"已认证":"未实名"}}
							<a href="javascript:[0];" @click="openwin(0)" style="color:red; margin-left: 10px;">去认证</a>
						</div>
					</div>
					<div class="box" v-if="udata.isbaofu">
						<div class="text">
							<span class="redxx">*</span>
							银行卡：
						</div>
						<div class="rt">
							{{udata.isbank?"已绑定":"未绑定"}}

							<a href="javascript:[0];" @click="openwin(udata.ispwd?2:1)"
								style="color:red; margin-left: 10px;">{{udata.isbank?"解绑银行卡":"绑定银行卡"}}</a>
						</div>
					</div> -->
					<!-- <div class="box" v-if="udata.isbaofu&&udata.isbank">
						<div class="text">
							<span class="redxx">*</span>
							支付密码：
						</div>
						<div class="rt">
							{{udata.ispwd?"已设置":"未设置"}}
							<a href="javascript:[0];" @click="openwin(udata.ispwd?4:3)"
								style="color:red; margin-left: 10px;">{{udata.ispwd?"修改密码":"设置密码"}}</a>
						</div>
					</div>

					<div class="box" v-if="udata.isbaofu&&udata.isbank">
						<div class="text">
							<span class="redxx">*</span>
							余额：
						</div>
						<div class="rt">
							{{udata.amount}}
							<a href="javascript:[0];" @click="openwin(5)" style="color:red; margin-left: 10px;">提现</a>
						</div>
					</div> -->

					<!-- <div class="box">
						<div class="text">
							<span class="redxx">*</span>
							网银类型：
						</div>
						<div class="rt layui-form">
							<select name="BankCode" lay-filter="selctOnchange">
								<option value="">请选择银行</option>
								<option :value="item.value" v-for="(item,index) in blist" :key="index">{{item.name}}</option>
							</select>
						</div>
					</div> -->
					<!-- <div class="box">
						<div class="text">
							<span class="redxx">*</span>
							银行卡号：
						</div>
						<div class="rt">
							<input type="text" v-model="dataValues.BankCard" class="input" placeholder="请输入银行卡号">
						</div>
					</div>
					<div class="box">
						<div class="text">
							<span class="redxx">*</span>
							支付密码：
						</div>
						<div class="rt">
							<input type="password" v-model="dataValues.PayPwd" class="input" placeholder="请输入支付密码">
						</div>
					</div> -->

					<button class="btn btn-small btn-primary Personalbtn" :disabled="IsChecked"
						@click="AddBindCard">绑定银行卡</button>
				</div>

				<div class="next-table next-table-medium">
					<el-table :data="list" style="width: 100%" border>
						<el-table-column prop="BankCard" label="银行卡号" align="center"> </el-table-column>
						<el-table-column prop="protocol" label="协议号" align="center"> </el-table-column>
						<el-table-column prop="smsNo" label="绑卡流水号" align="center"> </el-table-column>
						<el-table-column label="交易权限" align="center">
							<template slot-scope="{row}">
								<el-tag type="success" v-if="row.isOpen>0"> 已开通 </el-tag>
								<el-tag type="danger" v-else> 未开通 </el-tag>
							</template>
						</el-table-column>
						<el-table-column label="操作" align="center">
							<template slot-scope="{row}">
								<el-button type="text" v-if="row.isOpen<=0"
									@click="kaitongjiaoyiBank(row)">开通交易</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<el-dialog title="绑定银行卡" :visible.sync="dialogVisible" width="80%" destroy-on-close>
					<div v-if="!IsBindConfirm">
						<el-form ref="form" :model="dataValues" label-width="80px">
							<el-form-item label="姓名">
								<el-input v-model="dataValues.RealName"></el-input>
							</el-form-item>
							<el-form-item label="手机号码">
								<el-input v-model="dataValues.phoneNum"></el-input>
							</el-form-item>
							<el-form-item label="银行卡号">
								<el-input v-model="dataValues.BankCard"></el-input>
							</el-form-item>
							<el-form-item label="身份证">
								<el-input v-model="dataValues.certificatesNo"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" style="background-color: #0C6A6A;border: none;"
									:disabled="IsChecked" @click="SubmitBindCard">提交</el-button>
							</el-form-item>
						</el-form>
					</div>
					<div v-else>
						<el-form ref="form1" :model="bindCardConfirmQuery" label-width="100px">
							<el-form-item label="银行卡号">
								<el-input v-model="dataValues.BankCard" :disabled="true"></el-input>
							</el-form-item>
							<el-form-item label="流水号">
								<el-input v-model="bindCardConfirmQuery.smsNo" :disabled="true"></el-input>
							</el-form-item>
							<el-form-item label="短信验证码">
								<el-input v-model="bindCardConfirmQuery.smsCode"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" style="background-color: #0C6A6A;border: none;"
									:disabled="IsChecked" @click="SubmitbindCardConfirm">提交</el-button>
							</el-form-item>
						</el-form>
					</div>
				</el-dialog>

			</div>
			<!-- 我的订单主要内容END -->
		</div>
	</div>
</template>
<script>
	const defaultData = {
		Id: 0,
		RealName: '', //姓名
		phoneNum: '', //手机号
		BankCard: '', //银行卡号
		certificatesNo: '' //身份证		
	}

	export default {
		name: 'Order',
		data() {
			return {
				blist: [{
						name: '招商银行',
						value: '3001'
					},
					{
						name: '工商银行',
						value: '3002'
					},
					{
						name: '建设银行',
						value: '3003'
					},
					{
						name: '农业银行',
						value: '3005'
					},
					{
						name: '民生银行',
						value: '3006'
					},
					{
						name: '中国银行',
						value: '3026'
					},
					{
						name: '邮政储蓄银行',
						value: '3038'
					},
					{
						name: '兴业银行',
						value: '3009'
					},
					{
						name: '交通银行',
						value: '3020'
					},
					{
						name: '光大银行',
						value: '3022'
					},
					{
						name: '北京银行',
						value: '3032'
					},
					{
						name: '中信银行',
						value: '3039'
					},
					{
						name: '平安银行',
						value: '3035'
					},
					{
						name: '上海农商银行',
						value: '3037'
					},
					{
						name: '华夏银行',
						value: '3050'
					},
					{
						name: '浙商银行',
						value: '3043'
					},
				],
				list: [],
				dataValues: Object.assign({}, defaultData),
				IsChecked: false,
				udata: {
					isbaofu: false,
					isbank: false,
					ispwd: false
				},
				IsBindConfirm: false,
				bindCardConfirmQuery: {
					smsNo: '',
					smsCode: ''
				},
				dialogVisible: false
			};
		},
		created() {
			this.LoadData()
		},
		mounted() {
			var that = this
			// layui.use('form', () => {
			// 	//获取表单元素
			// 	var form = layui.form;
			// 	form.render();
			// 	form.on('select(selctOnchange)', function(data) {
			// 		var e = data.elem;
			// 		//获取选中的值
			// 		console.log(data.value)
			// 		that.dataValues.BankCode = data.value
			// 		//获取选中的文本
			// 		var text = e[e.selectedIndex].text;
			// 		that.dataValues.BankName = text
			// 	});
			// })
		},
		methods: {
			async openwin(type) {
				//type  0-实名认证 1-绑定银行卡 2-解绑银行卡 3-设置密码 4-修改密码

				var data = {
					queryType: type
				}
				const res = await this.postdata('/User/UserBaoFUH5', data)
				if (res.code == 200) {
					window.open(res.data, "_blank");
				}
			},
			async LoadData() {
				const res = await this.postdata('/User/UserBankList', {
					page: 1,
					limit: 100
				})
				if (res.code == 200) {
					this.list = res.data.items
					if (this.list.length > 0) {
						//this.dataValues = this.list[0]

						// var that = this
						// layui.use('form', () => {
						// 	//获取表单元素
						// 	var form = layui.form;
						// 	form.render();
						// 	form.on('select(selctOnchange)', function(data) {
						// 		var e = data.elem;
						// 		//获取选中的值
						// 		console.log(data.value)
						// 		that.dataValues.BankCode = data.value
						// 		//获取选中的文本
						// 		var text = e[e.selectedIndex].text;
						// 		that.dataValues.BankName = text
						// 	});
						// })
					}
				}

			},
			async SaveUserBank() {
				// if (this.dataValues.BankCode == '' || !this.dataValues.BankCode) {
				// 	this.notifyError('银行类型不能为空');
				// 	return false
				// }
				// if (this.dataValues.BankCard == '' || !this.dataValues.BankCard) {
				// 	this.notifyError('卡号不能为空');
				// 	return false
				// }
				// if (this.dataValues.PayPwd == '' || !this.dataValues.PayPwd) {
				// 	this.notifyError('支付密码不能为空');
				// 	return false
				// }
				this.IsChecked = true
				const res = await this.postdata('/User/OperationUserBank', this.dataValues)
				if (res.code == 200) {
					this.notifySucceed("保存成功");
					this.LoadData()
				}
				this.IsChecked = false
			},
			async SubmitBindCard() {
				this.IsChecked = true
				const res = await this.postdata('/User/ePay_bindCard', this.dataValues)
				this.IsChecked = false
				if (res.code == 200) {
					var result = res.data
					console.log(result)
					if (result.returnCode != '0000') {
						this.notifyError(result.returnMsg);
					} else {
						this.bindCardConfirmQuery.smsNo = result.smsNo
						this.IsBindConfirm = true
					}
				}
			},
			async SubmitbindCardConfirm() {
				this.IsChecked = true
				const res = await this.postdata('/User/ePay_bindCardConfirm', this.bindCardConfirmQuery)
				this.IsChecked = false
				if (res.code == 200) {
					var result = res.data
					console.log(result)
					if (result.returnCode != '0000') {
						this.notifyError(result.returnMsg);
					} else {
						this.notifySucceed("保存成功");
						this.dialogVisible = false
						this.LoadData()
					}
				}
			},
			AddBindCard() {
				this.IsBindConfirm = false
				this.bindCardConfirmQuery.smsCode = ""
				this.dataValues = Object.assign({}, defaultData)
				this.dialogVisible = true
			},
			kaitongjiaoyiBank(row) {
				this.dataValues.BankCard = row.BankCard
				this.bindCardConfirmQuery.smsNo = row.smsNo
				this.bindCardConfirmQuery.smsCode = ""
				this.IsBindConfirm = true
				this.dialogVisible = true
			}


		}

	}
</script>
<style scoped>
	/* 订单为空的时候显示的内容CSS */
	.userInfo .Personalbtn {
		margin-top: 16px;
	}

	.clearfix {
		clear: both;
	}

	.clearfix:after,
	.clearfix:before {
		content: " ";
		clear: both;
	}

	.box {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
	}

	.userInfo .text {
		width: 100px;
		font-size: 15px;
	}

	.userInfo .rt {}

	.userInfo .rt .input {
		border: 1px solid #bbb;
		font-size: 15px;
		line-height: 30px;
		padding: 0 6px;
		border-radius: 4px;
	}



	.box-bd {
		margin-top: 16px;
	}

	.uc-box .order-empty {
		margin: 0 auto;
	}

	.uc-box .order-empty .empty {
		height: 300px;
		padding: 0 0 130px 455px;
		margin: 65px 0 0;
		background: url(../../assets/imgs/cart-empty.png) no-repeat 32px 0;
		color: #b0b0b0;
		overflow: hidden;
	}

	.uc-box .order-empty .empty h2 {
		margin: 70px 0 15px;
		font-size: 36px;
	}

	.uc-box .order-empty .empty p {
		margin: 0 0 20px;
		font-size: 20px;
	}

	.uc-box {
		background: #fff
	}

	.uc-box .uc-content-box {
		margin: 0 48px
	}

	.uc-box .uc-content-box .box-hd .title {
		margin: 0;
		font-size: 30px;
		font-weight: 400;
		line-height: 68px;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .more {
		border-bottom: 0;
	}

	.uc-box .uc-content-box .box-hd .filter-list {
		float: left;
		margin: 0;
		padding: 18px 0;
		list-style-type: none;
		font-size: 16px;
		line-height: 1.25
	}

	.uc-box .uc-content-box .box-hd .filter-list a {
		color: #757575;
		cursor: pointer
	}

	.uc-box .uc-content-box .box-hd .filter-list li {
		float: left;
		padding: 0 20px;
		border-left: 1px solid #e0e0e0;
		color: #757575
	}

	.uc-box .uc-content-box .box-hd .filter-list li.first {
		padding-left: 0;
		border-left: 0
	}

	.uc-box .uc-content-box .box-hd .filter-list li.active,
	.uc-box .uc-content-box .box-hd .filter-list li.active a,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active,
	.uc-box .uc-content-box .box-hd .filter-list li.tab-active a {
		color: #ff6700
	}

	.hide {
		display: none !important
	}

	.uc-order-item {
		position: relative
	}

	.btn {
		display: inline-block;
		width: 158px;
		height: 38px;
		padding: 0;
		margin: 0;
		border: 1px solid #b0b0b0;
		font-size: 14px;
		line-height: 38px;
		text-align: center;
		color: #b0b0b0;
		cursor: pointer;
		-webkit-transition: all .4s;
		transition: all .4s
	}

	.btn:hover {
		text-decoration: none;
		/* color: #fff */
	}

	.btn:focus {
		outline: 0
	}

	.btn:active {
		-webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18);
		box-shadow: inset 0 2px 4px rgba(0, 0, 0, .18)
	}

	.btn[disabled] {
		border-style: dashed !important;
		border-color: #e0e0e0;
		background-color: #fff !important
	}

	.btn-disabled,
	.btn[disabled] {
		color: #b0b0b0 !important;
		cursor: default !important
	}

	.btn-disabled {
		background: #e0e0e0 !important;
		border-color: #e0e0e0 !important
	}

	.btn-small {
		width: 118px;
		height: 28px;
		font-size: 12px;
		line-height: 28px
	}

	.btn-large {
		width: 178px;
		height: 48px;
		line-height: 48px
	}

	.btn-biglarge {
		width: 298px;
		height: 52px;
		line-height: 52px;
		font-size: 16px
	}

	.btn-block {
		display: block;
		width: 100%;
		padding-left: 0;
		padding-right: 0
	}

	.btn-primary {
		background: #0C6A6A;
		border-color: #0C6A6A;
		color: #fff;
	}

	button.btn,
	input.btn {
		width: 160px;
		height: 40px
	}

	button.btn-small,
	input.btn-small {
		width: 120px;
		height: 30px
	}

	.ordernum {
		color: #757575;
	}

	.next-table {
		position: relative;
		margin: 48px 0;
	}


	/* 订单为空的时候显示的内容CSS END */
</style>